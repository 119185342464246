let util = {}

/**
 * 第一步 先打开app看是否有下载
 * 第二步 显示提示下载选择手机系统版本下载
 * 第三步 ios下载 直接跳转 AppStore 到软件下载界面
 * Android 下载 用网页直接下载安装包？
 */
util.androidApp = function() {
	//安卓app的scheme协议
	location.href = "entrance://com.mingyang.pet/splash";
	setTimeout(function() {
		let hidden = window.document.hidden || window.document.mozHidden || window.document
			.msHidden || window.document.webkitHidden
		if (typeof hidden == "undefined" || hidden == false) {
			// 跳转到下载界面 这里待更改成 主人你好app的路径
			location.href = "http://static2.kza666.com/apk/local.apk";
		}
	}, 500);
},

util.iosApp = function() {
	// 有就打开app 
	location.href = "https://apps.apple.com/cn/app/id1609567450";
	// 这个是ios系统
	setTimeout(function() {
		let hidden = window.document.hidden || window.document.mozHidden || window.document
			.msHidden || window.document.webkitHidden
		if (typeof hidden == "undefined" || hidden == false) {
			//App store下载地址 这里待更改成 主人你好app的路径
			location.href = "https://apps.apple.com/cn/app/id1609567450";
		}
	}, 500);
}



export default util;
