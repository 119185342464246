<template>
	<div class="about-wrap">
		<div class="about-moohaa">
			<div style="text-align: center;">
				<div class="about-tilte-img">
					<b-img src="http://static.kza666.com/kza/2022-06-08/1fd9c455-9295-411a-a263-0aba0a0d3def" fluid>
					</b-img>
					<div class="over-title-ch">
						关于陌哈陌哈
					</div>
				</div>
				<div >
					<b-container fluid="lg" class="about-moohaa-content">
						<b-row class="moohaa-img" >
							<b-col >
								<b-img src="http://static.kza666.com/kza/2022-06-08/a9457128-9338-4632-a433-f19113a01e02" fluid>
								</b-img>
							</b-col>
						</b-row>
						<b-row >
							<b-col>
								<!-- <span
									class="content-mh">陌哈陌哈</span> -->
									长沙科致安科技信息有限公司旗下品牌陌哈陌哈。秉承“以宠为本”的品牌理念，共担爱与责任，致力于用智能守护万千宠物的成长安全与健康，为宠物与宠物主构建全新的智能养宠新方式，实现人宠交互的多元趣味生活。
							</b-col>
						</b-row>
						<b-row >
							<b-col>
							陌哈陌哈为守护宠物安全，在智能安全领域为宠物研发了宠不失智能宠物项圈。自研“防丢防盗防抱走”的物理防拆设计，荣获了国家专利认证(实用新型专利：ZL202121405274.0)；定位精度打破了市场上伪定位、定位器精度不高、信号不强等局限性问题，定位精度高达3米，无论身处何地，都能找到爱宠准确位置，做到有效防丢。同时，还研发了人宠智能居家监控设备，产品满足宠物智能监控、实时语音、夏季风扇的需求，又满足宠主照明、预警等多重场景应用，做到人宠双向的便利智能生活。
							</b-col>
						</b-row>
					<!-- 	<b-row >
							<b-col>
							陌哈陌哈APP，为宠主搭建智能生活载体，1个app掌握宠物智能产品应用。同时，为更好地守护宠物健康，解决宠主“看病难”问题，为宠主搭建智能化、便利化宠物保险、宠物健康医院服务平台。	
							</b-col>
						</b-row> -->
						<b-row>
							<b-col>
							平台侧重于宠物身心健康与宠主生活品质的提升。宠主能为宠物在线相亲、交友；也能与陌生宠友进行趣味匹配互动，体验专属宠友的萌趣社交。
							</b-col>
						</b-row>
						<b-row>
							<b-col>
							平台从人宠的双向情感出发，守护宠物平安与健康，提升宠主的品质趣味生活，用智能化构建人宠共享的幸福、和谐、趣味多元的养宠生活。
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</div>
		<div class="about-content">
			<div class="about-title">
				<p class="over-title-ch">品牌故事</p>
				<p class="over-title-en-18">BRAND STORY</p>
			</div>
			<div>
				<b-container>
					<b-row align-h="around" align-v="center">
						<b-col sm class="mr-auto ">
							<b-img src="http://static.kza666.com/kza/2022-06-09/bb5fcff1-14a5-4b36-8ef5-7962793cd6d4"
								fluid>
							</b-img>
						</b-col>
						<b-col sm class="mr-auto">
							<div class="outer-container">
								<div class="inner-container">
									<div class="outer-content">
										<uiAboutStory></uiAboutStory>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div class="about-content" style="background-color: #F5F5F5;">
			<div class="about-title">
				<p class="over-title-ch">企业新闻</p>
				<p class="over-title-en-18 ">CONRPORATE NEWS</p>
			</div>
			<div>
				<b-container class="bv-example-row">
					<b-row align-h="around" align-v="start">
						<b-col sm class="mr-auto p-3" v-for="news in articleList">

							<figure class="hover-news">
								<router-link :to="news.webUrl">
									<div :style="itemObject(news.imgUrl)" class="news-img"></div>
									<figcaption style="margin-left: 2px;">{{news.title}}</figcaption>
									<div style="margin-left: 2px;">{{news.time}}</div>
								</router-link>
							</figure>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div class="about-content">
			<div class="about-title">
				<p class="over-title-ch">社会责任</p>
				<p class="over-title-en-18">SOCIAL RESPONSIBILITY</p>
			</div>
			<div>
				<b-container >
					<b-row align-v="center" v-for="item in enterList">
						<div class=" p-3  social-left" style="text-align: center;">
							<b-img :src="item.imgUrl" fluid>
							</b-img>
						</div>
						<b-col sm class="mr-auto social-left" style="text-align: left;">
							<div class="social-tilte">{{item.title}}</div>
							<div class="social-content">{{item.content}}</div>
						</b-col>

						<div class="mr-auto p-3 social-center " style="text-align: center;">
							<b-img :src="item.imgUrl" fluid>
							</b-img>
							<b-col sm class="mr-auto" style="text-align: left;">
								<div class="social-tilte">{{item.title}}</div>
								<div class="social-content">{{item.content}}</div>
							</b-col>
						</div>
					</b-row>
				</b-container>
			</div>
		</div>
		<div class="about-content" style="background-color: #F5F5F5;">
			<div class="about-title">
				<p class="over-title-ch">联系我们</p>
				<p class="over-title-en-18">CONTACT US</p>
			</div>
			<div id="contactUs">
				<b-container class="bv-example-row" style="background-color: #fff;">
					<b-row>
						<b-col sm="6" class="mr-auto p-3">
							<div style="display: flex;">
								<div>
									<img src="@/assets/about/contant1.png" alt="" srcset="">
								</div>
								<div style="margin-left: 20px;">
									<div class="contact-name">公司地址</div>
									<div class="contant-tips">湖南省长沙市开福区双拥路656号</div>
								</div>
							</div>
						</b-col>
						<b-col sm="6" class="mr-auto p-3">
							<div style="display: flex;">
								<div>
									<img src="@/assets/about/contant3.png" alt="" srcset="">
								</div>
								<div style="margin-left: 20px;">
									<div class="contact-name">商务邮箱</div>
									<div class="contant-tips">kzashangwu@kezhiankeji.com</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="6" class="mr-auto p-3">
							<div style="display: flex;">
								<div>
									<img src="@/assets/about/contant2.png" alt="" srcset="">
								</div>
								<div style="margin-left: 20px;">
									<div class="contact-name">官方电话</div>
									<div class="contant-tips">400-100-3735</div>
								</div>
							</div>
						</b-col>
						<b-col sm="6" class="mr-auto p-3">
							<div style="display: flex;">
								<div>
									<img src="@/assets/about/contant4.png" alt="" srcset="">
								</div>
								<div style="margin-left: 20px;">
									<div class="contact-name">简历投递</div>
									<div class="contant-tips">kzahr@kezhiankeji.com</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
			<div style="margin-top: 20px;">
				<b-container class="bv-example-row" style="background-color: #ffffff;text-align: center;">
					<b-row>
						<b-col class="mr-auto p-3 shop-hover">
							<img src="@/assets/about/shop1.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈京东旗舰店</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-jd.jpg">
							</span>
						</b-col>
						<b-col class="mr-auto p-3  shop-hover">
							<img src="@/assets/about/shop2.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈淘宝旗舰店</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-tb.jpg">
							</span>
						</b-col>
						<!-- <b-col class="mr-auto p-3  shop-hover">
							<img src="@/assets/about/shop3.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈天猫旗舰店</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-tb.jpg">
							</span>
						</b-col> -->
					<!-- 	<b-col class="mr-auto p-3  shop-hover">
							<img src="@/assets/about/shop4.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈抖音号</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-dy.jpg">
							</span>
						</b-col> -->
						<b-col class="mr-auto p-3  shop-hover">
							<img src="@/assets/about/shop5.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈新浪微博</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-wb.jpg">
							</span>
						</b-col>
						<b-col class="mr-auto p-3  shop-hover">
							<img src="@/assets/about/shop6.png" alt="" srcset="">
							<div class="shop-name">陌哈陌哈微信公众号</div>
							<span class="span-ewm">
								<img src="@/assets/about/code/code-gzh.jpg">
							</span>
						</b-col>
					</b-row>

				</b-container>
			</div>
		</div>


	</div>
</template>

<script>
	import uiAboutStory from '../../components/ui-about-story.vue'
	export default {
		components: {
			uiAboutStory
		},
		computed: {
			itemObject(item) {
				return (item) => ({
					backgroundImage: 'url("' + item + '")'
				})
			}
		},
		data() {
			return {
				newsImgs: {
					backgroundImage: 'url("' +
						'http://static.kza666.com/kza/2022-05-25/53e3c83d-4494-48f0-a47f-320f7db5034c' + '")',
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				},
				enterList: [{
						imgUrl: require('@/assets/about/duty1.png'),
						title: "构建和谐人宠平台",
						content: "我们致力于构建一个人与宠物和谐相处的平台。以萌宠社交为纽带，传递保护动物、关爱宠物的正能量，坚持做一个有责任有爱心的企业。坚决反对任何虐待、残害小动物的行为和思想。打造一个人宠美好相处、充满正能量和爱的家园。",
					},
					{
						imgUrl: require('@/assets/about/duty2.png'),
						title: "倡导科学文明养宠 ",
						content: "我们倡导用户做一个有责任感、珍爱动物生命的新时代人类；倡导社会合法养宠、文明养宠、科学养宠，让每一只爱宠都能在人类社会健康、安全、快乐地成长。提倡以领养代替购买、遛狗牵狗绳等文明行为，以此建设和谐文明的人宠社会。",
					},
					{

						imgUrl: require('@/assets/about/duty3.png'),
						title: "发起爱宠公益行",
						content: "通过陌哈陌哈平台，让更多的铲屎官看到亟待领养的流浪动物。为小动物找到家庭，为爱宠者筛选健康的新成员。",
					}
				],
				articleList: [{
						webUrl: "/articlesOne",
						imgUrl: "http://static.kza666.com/kza/2022-06-17/5fca336d-481b-4944-9b6f-b392527b1057",
						title: "为宠物安全而研发——宠不失智能定位安全项圈",
						time: "2022年5月24日"
					},
					{
						webUrl: "/articlesTwo",
						imgUrl: "http://static.kza666.com/kza/2022-06-17/4a5982a3-b819-40c6-b5dc-de1f4caed28e",
						title: "狗狗爱乱跑？四重防丢科技，精确追踪实时位置，跑再远也能找回！ ",
						time: "2022年5月24日"
					},
					{
						webUrl: "/articlesThree",
						imgUrl: "http://static.kza666.com/kza/2022-06-17/b7d20956-e47f-41fe-9ead-8a1a76cfea49",
						title: "拒绝宠物丢失流浪：选对宠物防丢智能产品，能护它一生安全",
						time: "2022年5月24日"
					},
				],
				shopList: [{
					shopName: "",
					shopLogo: " require('@/assets/about/shop1.png')",
					shopCode: "",
				}]
			}
		},
		mounted() {
			this.$nextTick(() => this.getLocal());
		},
		methods: {
			getLocal() {
				let select = localStorage.getItem("contact");
				let elm = document.getElementById(select);
				if (select) {
					// 使页面滚动到需要被定为的地方
					elm.scrollIntoView(true);
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.about-wrap {
		font-family: Microsoft YaHei;
	}

	// 关于陌哈布局
	.about-moohaa {
		background-color: #f5f5f5;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	// 关于陌哈 标题
	.about-moohaa-title {
		font-size: 36px;
		font-weight: 600;
	}

	
	// 陌哈陌哈介绍 文字样式
	.about-moohaa-content {
		text-align: left;
		text-align: justify;
		font-size: 18px;
		line-height: 30px;
		letter-spacing: 1px;
		// 放大陌哈陌哈
		.content-mh {
			font-size: 24px;
		}
	}
	// 关于陌哈 图片 上下距离
	.moohaa-img{
		margin: 20px 0;
	}

	// 下面展示内容布局
	.about-content {
		padding: 100px 0;
	}

	// 每个展示内容样式
	
	.about-title {
		text-align: center;
		margin-bottom: 40px;
	}


	// 品牌故事滚动条
	.outer-container,
	.outer-content {
		height: 350px;
	}

	// 品牌故事滚动条
	.outer-container {
		position: relative;
		overflow: hidden;
	}

	// 故事展示框
	.inner-container {
		width: calc(100% + 20px);
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	// 企业新闻
	.hover-news {
		.news-img {
			width: 100%;
			height: 300px;
			background-repeat: no-repeat;
			background-size: cover;
		}

		figcaption {
			margin: 25px auto;
			font-size: 18px;
			font-weight: 400;
			color: #222222;
		}

		div {
			font-size: 14px;
			font-weight: 400;
			color: #999999;
			line-height: 30px;
		}

		&:hover {
			box-shadow: 0 0 2px 2px #dadada;
		}
	}

	//社会责任
	.social-tilte {
		font-size: 24px;
		font-weight: 600;
		color: #27C8B0;
		line-height: 30px;
		margin-bottom: 30px;
	}

	.social-content {
		font-size: 18px;
		font-weight: 400;
		line-height: 30px;
	}

	// 联系我们 联系方式 整体样式布局
	.bv-example-row{
		padding: 40px 20px;
	}
	.contact-name {
		font-size: 24px;
		font-weight: 600;
	}

	// 联系说明
	.contant-tips {
		font-size: 20px;
		font-weight: 400;
		color: #999999;
	}

	.shop-hover {
		position: relative;

		&:hover {
			.span-ewm {
				display: block;
			}
		}
	}

	//联系二维码
	.span-ewm {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 100px;
		height: 100px;
		bottom: 95%;
		display: none;
		background-color: #27C8B0;
		padding: 1px;

		img {
			width: 100%;
			height: 100%;

		}

		&:after {
			content: '';
			position: absolute;
			bottom: -6px;
			left: 50%;
			margin-left: -6px;
			border-top: 6px solid #27C8B0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
		}
	}

	// 店铺说明
	.shop-name {
		padding-top: 10px;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #222222;
	}

	@media screen and(max-width:576px) {
		.contant-show {
			display: none;
		}


		// 关于我们的英文图
		.about-tilte-img 
		.img-fluid {
			max-width: 65%;
		}

		//当时大屏 把 社会责任左右齐版本 隐藏
		.social-left {
			display: none;
			
		}

		//当内容居中，把社会责任的标题 隔开与上一个内容
		.social-tilte {
			margin-top: 20px;
			text-align: center;
		}

	}

	@media screen and(min-width:577px) {

		//当时大屏 把 社会责任上下对齐版本 隐藏
		.social-center {
			display: none;
		}
	}

	@media only screen and(max-width:991px) {
		.about-moohaa {
			padding-top: 80px;
			padding-bottom: 60px;
		}

		//关于陌哈样式
		.about-moohaa-content {
			font-size: 14px;
			.content-mh {
				font-size: 16px;
			}
		}
		// 下面展示内容布局
		.about-content {
			padding-top: 80px;
			padding-bottom: 60px;
		}
		// 每个展示内容样式
		.about-title {
			margin-bottom: 30px;
		}
		// 手机端 企业新闻 样式
		.hover-news {
			figcaption {
				margin: 5px auto;
				font-size: 14px;
			}
		
			div {
				font-size: 12px;
			}
		}

		//社会责任
		.social-tilte {
			font-size: 16px;
			margin-bottom: 15px;
		}

		.social-content {
			font-size: 14px;
		}

		// 联系我们 联系方式
		.contact-name {
			font-size: 16px;
		}

		// 联系说明
		.contant-tips {
			font-size: 14px;
		}

		// 店铺说明
		.shop-name {
			font-size: 12px;
		}
		
	}

	a:hover {
		text-decoration: none;
	}

</style>
