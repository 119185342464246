<template>
	<div class="articles-warp">
		<div class="details-content">
			<div class="details-content-title">为宠物安全而研发——宠不失智能定位安全项圈</div>
			<div class="details-content-time">发布于 2021-12-18 17:51</div>
			<div>
				如今，宠物对于绝大多数的宠物主来说，都是当挚爱且重要的家庭成员来呵护。和宠物在一起生活，就是单纯简单的——你陪我一辈子，我给你一个家。没有人与人之间复杂的交往与情感，尤其是狗狗，从你接它回家那一天起，它的一生就只会爱你。
			</div>
			<div class="img-div">
				<img style="max-height:300px;max-width: 70%;" :src="imgOne">
			</div>
			<div>
				养了狗狗，担心总在多面，其中让铲屎官不能接受的就是“毛孩子”走失。虽说现在是遛狗要牵狗绳的年代，但猖獗的狗贩子、偶尔的疏忽大意、撒手没的意外……都有可能导致毛孩子丢失，且很难再找回。
			</div>
			<div class="img-div">
				<img style="max-height: 600px;width: 40%;" :src="imgTwo">
			</div>

			<div>
				<b>毛孩子走丢了，怎样才能提升被找回的概率？</b>
			</div>
			<div>
				陌哈陌哈为宠物安全而研发的宠不失智能宠物项圈，就能很好地防止宠物丢失、提升宠物丢失后被找回的概率。
			</div>
			<div>
				陌哈陌哈虽是宠物智能行业的新生品牌，但创始人多年前就深耕于人工智能领域，拥有一批创意丰富、实力雄厚的研发团队。经过多方调研与上万次实验，潜心研发了这款在硬件与软件上均优于市场上普通定位产品——宠不失智能宠物项圈。
			</div>
			<div>
				<b>
					在硬件上，宠不失拥有自研防拆防丢设计、基于真4G定位技术；在“软件”上，为每一只狗狗开启了宠物防丢失保障，同时研发了一款致力于宠物安全与健康、多元娱乐养宠生活的APP。
				</b>
			</div>
			<div class="img-div">
				<img style="max-height:400px;width: 50%;" :src="imgThree">
			</div>
			<div>
				<b>
					荣获国家专利(实用新型专利：ZL202121405274.0)——防拆防丢设计
				</b>
			</div>
			<div>
				普通宠物定位产品都面临一个致命的问题：轻易拆卸，防丢不防偷。而宠不失智能宠物项圈则拥有双重保障。
			</div>
			<div>
				项圈采用了可承受100KG重力的防拆卸设计，人力掰扯、普通剪刀是无法扯断或剪断项圈；搭配千位数安全密码锁，除了宠物主以外，陌生人无法打开，有效防止人为拆卸的情况，大大提升了安全保障。
			</div>
			<div>
				<b>
					基于真4G的多重定位，实时掌握爱宠行踪
				</b>
			</div>
			<div>
				宠不失智能宠物项圈在基于真4G的基础上，集合了北斗、WIFI、AGPS、LBS、GPS多重定位技术于一身，真正做到了实时、准确、低延迟的定位。为什么一定要强调真4G？
			</div>
			<div>
				<b>
					1、宠物定位产品若继续沿用被清网的2G/3G定位，极易导致定位偏差。
				</b>
			</div>
			<div>
				<b>
					2、5G定位固然好，但技术还不够成熟且对续航的要求极高。若采用5G定位，是难以满足宠物定位产品要求的小体积与强续航的。
				</b>
			</div>
			<div>
				考虑爱宠安全，选择采用真4G定位，定位精度误差不超过3米的宠不失智能宠物项圈是合适的。
			</div>
			<div>
				<b>
					实时定位，紧急寻宠更具效率
				</b>
			</div>
			<div>
				在真4G定位的基础上，宠不失还加强了定位的刷新频率。普通宠物定位产品为了延长设备续航，降低了定位功能的刷新频率，宠不失高能做到10S刷新一次位置，能够最大程度避免时隔半小时或一小时才更新位置的情况，在有效时间内寻找并救助宠物。
			</div>
			<div>
				宠不失智能宠物项圈为了保证正常的续航，支持用户在手机APP【陌哈陌哈】上自行设置上传频率，默认为5分钟更新一次定位，紧急寻宠时可设为10S刷新，能大程度提高寻宠效率。
			</div>
			<div>
				<b>
					宠物防丢失保障计划——敢丢，就敢赔
				</b>
			</div>
			<div>
				因为自信，所以承诺。宠不失智能宠物项圈向一个用户承诺：因产品质量问题而导致的宠物丢失，均赠送与项圈等价值的保障赔偿服务。让宠主买得放心，用得安心。
			</div>
			<div>
				<b>
					宠物安全健康、多元娱乐养宠生活——【陌哈陌哈】APP
				</b>
			</div>
			<div>
				陌哈陌哈APP在便捷管理项圈功能的基础上，同时致力于宠物安全健康服务，为宠物主提供宠物保险、宠物在线健康医院、萌宠训犬学院等服务，及以萌宠为契机的专属全国宠友的萌趣社交体验。
			</div>
			<div>
				1个APP给予宠物主高质量养宠生活服务，也为宠物主打造专属“宠友圈”，随时随地探索全国宠友的养宠动态，记录爱宠成长点滴。
			</div>
			<div>
				宠物是我们重要且可爱的“家人”，陌哈陌哈宠不失智能宠物项圈的宗旨，正是为宠物主用心守护爱宠安全！
			</div>

		</div>

			<div class="details-link">
				<div>
					<router-link to="/articlesTwo">下一篇：狗狗爱乱跑？四重防丢科技，精确追踪实时位置，跑再远也能找回！</router-link>
				</div>
			</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				imgOne:"http://static.kza666.com/kza/2022-05-25/a38b71ca-aba2-4314-bb6b-596911b4373e",
				imgTwo:"http://static.kza666.com/kza/2022-05-25/53e3c83d-4494-48f0-a47f-320f7db5034c",
				imgThree:"http://static.kza666.com/kza/2022-05-25/02acbdd5-dee0-47f1-9cca-a8ca0584860e"
			}
		}
	}
</script>

<style lang="scss">
	.articles-warp {
		width: 100%;
		text-align: justify;
		word-break: break-all;
	}

	.details-content {
		font-family: Microsoft YaHei;
		font-weight: 400;

		>div {
			line-height: 30px;
			margin: 20px 0;
		}

		.img-div {
			margin: 20px 0;
			text-align: center;

			img {
				margin: 5px auto;
				cursor: pointer;
			}
		}

		.details-content-title {
			font-size: 32px;
			font-weight: 600;
			line-height: 48px;
		}

		.details-content-time {
			margin: 26px 0;
			font-size: 16px;
			color: #999999;
			line-height: 12px;
		}
	}
</style>
