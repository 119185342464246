<template>
	<div class="home-warp">
		<div  v-if="phone" >
			<video width="100%" :src="videoUrl" type="video/mp4" class="home-video" autoplay loop muted data-object-fit
				x5-video-player-fullscreen="true">
			</video>
		</div>
		<div class="home-picture-warp">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm style="color: #fff;" class="md-font-algin">
						<div>
							<p class="over-title-en">MOOHAA</p>
							<p class="over-title-ch">陌哈陌哈</p>
						</div>
						<div class="home-moha-content">
							<p>以宠为本，智能守护</p>
							<p>致力宠物安全与健康</p>
						</div>
						<div style="margin-bottom: 20px;">
							<p>
								陌哈陌哈秉承<b>“以宠为本”</b>理念
							</p>
							<p>
								肩负爱与责任
							</p>
							<p>	
								致力于智能守护万千宠物安全与健康
							</p>
						</div>
					</b-col>
					<b-col sm style="text-align: center;" class="home-sm-top">
						<b-img src="http://static.kza666.com/kza/2022-06-07/05b79fe4-6495-4a55-954f-0b78a65427c6" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="home-grey-bg">
			<div style="width: 100%;">
				<div >
					<p class="over-title-en">OUR MISSION</p>
					<p class="over-title-ch">我们的使命</p>
				</div>
				<b-container class="bv-example-row">
					<b-row align-h="around" class="mission-tips">
						<b-col>
							<b-img src="http://static.kza666.com/kza/2022-06-07/05686dba-6e70-4474-aa7f-a4a70c63b4bd"
								fluid alt="Fluid-grow image"></b-img>
							<div style="margin-top: 10px;">智能守护宠物安全</div>
						</b-col>
						<b-col>
							<b-img src="http://static.kza666.com/kza/2022-06-07/2fd13590-9e83-44e1-9f9b-6253e1475ac7"
								fluid alt="Fluid-grow image"></b-img>
							<div style="margin-top: 10px;">规范保障宠物健康</div>
						</b-col>
						<b-col>
							<b-img src="http://static.kza666.com/kza/2022-06-07/bc617948-3225-4719-90fd-58c8f75d219c"
								fluid alt="Fluid-grow image"></b-img>
							<div style="margin-top: 10px;">构建趣味养宠生活</div>
						</b-col>
					</b-row>
				</b-container>
			</div>

		</div>
		<div class="home-introduce-warp sm-padding-bottom-0">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="md-font-algin algin-left">
						<div>
							<p class="over-title-en">LOVE WITHOUT LOSS</p>
							<p class="over-title-ch">宠不失，爱不失</p>
						</div>
						<div style="padding-top: 1.5rem;">
							<p>
								宠不失智能宠物项圈
							</p>
							<p>
								守护它安全
							</p>
						</div>
					</b-col>
					<b-col sm >
						<b-img src="http://static.kza666.com/kza/2022-06-07/c251e3a2-d64d-4f00-a8e9-8fbe6232100f" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="home-introduce-warp" style="background-color: #F5F5F5;">
				<b-container class="core-web">
					<b-row align-h="around" align-v="center">
						<b-col sm>
							<b-img src="http://static.kza666.com/kza/2022-06-07/4623f23b-820c-471e-a37a-545719def7cb" fluid
								alt="Fluid-grow image"></b-img>
						</b-col>
						<b-col sm class="md-font-algin algin-right">
							<div>
								<p class="over-title-en">HIT THE CORE</p>
								<p class="over-title-ch">共担责任，直击核心</p>
							</div>
							<div style="padding-top: 1.5rem;">
								<p>
									一站式宠物健康服务
								</p>
								<p>
									保障它健康
								</p>
							</div>
						</b-col>
					</b-row>
				</b-container>
				<b-container class="core-phone">
					<b-row align-h="around" align-v="center">
						<b-col sm class="md-font-algin algin-right">
							<div>
								<p class="over-title-en">HIT THE CORE</p>
								<p class="over-title-ch">共担责任，直击核心</p>
							</div>
							<div style="padding-top: 1.5rem;">
								<p>
									一站式宠物健康服务
								</p>
								<p>
									保障它健康
								</p>
							</div>
						</b-col>
						<b-col sm class="core-img">
							<b-img src="http://static.kza666.com/kza/2022-06-07/4623f23b-820c-471e-a37a-545719def7cb" fluid
								alt="Fluid-grow image"></b-img>
						</b-col>
					</b-row>
				</b-container>
		</div>
		<div class="home-introduce-warp">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="md-font-algin algin-left">
						<div>
							<p class="over-title-en">RICH AND DIVERSE</p>
							<p class="over-title-ch">人宠交互，丰富多元</p>
						</div>
						<div style="padding-top: 1.5rem;">
							<p>
								全国宠友专属APP —— 陌哈陌哈App
							</p>
							<p>
								趣味它生活
							</p>
						</div>
					</b-col>
					<b-col sm class="diverse-img">
						<b-img src="http://static.kza666.com/kza/2022-06-07/ff8c2b49-0294-462b-9fa6-312d7eef329e" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				screenWidth:document.body.clientWidth,
				phone:true,
				videoUrl: "http://static.kza666.com/kza/2022-05-24/8b9b2f7a-7977-49b0-9c79-8cd032e5a626",
			}
		},
		watch: {
			'screenWidth'(n, o) {
				console.log(n, "屏幕", o)
			}
		},
		mounted() {
			this.screenWidth = document.body.clientWidth;
			console.log(this.screenWidth,'屏幕宽')
			if(this.screenWidth <= 991){
				this.phone = false;
			}else{
				this.phone = true;
			}
		},
		methods: {
		
		}
	}
</script>

<style lang="scss" scoped>

	.home-warp {
		font-family: Microsoft YaHei;
		word-wrap: break-word;
	}
	.home-video {
		display: block;
	}

	// 图片背景
	.home-picture-warp {
		padding: 140px 0;
		background: url('../../assets/home/home-bg.png') no-repeat;
		background-size: 100%100%;
		display: flex;
		align-items: center;
	}
	.home-moha-content{
		font-weight: bold;
		color: #06F5D1;
		margin: 33px 0;
	}

	// 我们的使命
	.home-grey-bg {
		width: 100%;
		padding: 120px 0;
		display: flex;
		align-items: center;
		text-align: center;
		background-color: #F5F5F5;
	}

	.mission-tips {
		margin-top: 120px;
		font-size: 24px;
		font-weight: 400;
	}

	.home-introduce-warp {
		width: 100%;
		display: flex;
		align-items: center;
		text-align: center;
	}
	//共担责任 核心
	.core-phone{
		display: none;
	}

	
	@media screen and(max-width:576px) {
		.md-font-algin {
			text-align: center;
		}

		.img-fluid {
			max-width: 80%;
		}
	}

	@media screen and(min-width:577px) {
		.algin-right {
			text-align: right;
		}

		.algin-left {
			text-align: left;
		}
	}
	@media only screen and(max-width:991px) {
		.home-picture-warp {
			padding: 60px 0;
			background-size:cover;   
		}
		//陌哈陌哈 以宠为本说明样式
		.home-moha-content{
			margin: 18px 0;
		}
		//以宠为本 说明 图片 高度 和大小
		.home-introduce-warp {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.home-sm-top{
			margin-top: 30px;
			.img-fluid {
			    max-width: 80%;
			}
		}
		//共担责任 核心 手机端显示
		.core-phone{
			display: inherit;
		}
		//共担责任 核心 pc端隐藏
		.core-web{
			display: none;
		}
		// 我们的使命
		.home-grey-bg {
			padding: 60px  0;
		}
		//狗不失 图片靠底部
		.sm-padding-bottom-0{
			padding-bottom: 0;
			//狗狗图片
			.img-fluid {
			    max-width: 80%;
			    padding-right: 40px;
			}
		}
		// 共担责任 图片 顶部间距
		.core-img{
			margin-top: 20px;
		}
		//人宠交互 图片样式
		.diverse-img{
			.img-fluid {
			    max-width: 80%;
			    // padding-right: 40px;
			}
		}
		// 我们的使命 下面图标的小字 和距离
		.mission-tips{
			font-size: 14px;
			margin-top: 40px;
			// 文字使其变为一行
			.col{
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
			//我们的使命图片样式
			.img-fluid {
			    max-width: 50%;
			}
		}
	}
</style>
