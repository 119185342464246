<template>
  <div class="layout-warp">
    <el-container>
      <el-header height="90">
        <UiHeader></UiHeader>
      </el-header>
      <el-main style="margin-top: 90px;">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <UiFooter></UiFooter>
      </el-footer>
    </el-container>
    <!-- 活动弹窗 -->
    <actvity :end="end_time" />
  </div>
</template>

<script>
import UiHeader from "./components/header.vue";
import UiFooter from "./components/footer.vue";
import actvity from "@/views/activity/index.vue";
export default {
  components: {
    UiHeader,
    UiFooter,
    actvity
  },
  data() {
    return {
      end_time: "2022/11/13 23:59:59" // 注意时间格式，ios不支持2022-11-13这种形式；
    };
  }
};
</script>

<style scoped lang="scss">
.el-header {
  padding: 0 0;
}

.el-main {
  padding: 0;
}

.el-footer {
  padding: 0;
}
</style>