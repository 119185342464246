<template>
	<div class="product-wrap">
		<div class="product-buy-top">
			<div style="width: 100%;">
				<b-container>
					<b-row align-h="around" align-v="center">
						<b-col sm>
							<div>
								<p class="product-buy-titel">防丢防盗防抱走</p>
								<p class="product-buy-tips">宠不失智能宠物项圈 CBS1.1-S-PRO</p>
								<div class="product-buy-price">
									<span class="price-f" >￥</span> 599
								</div>
								<div @click="goShopping" class="go-shopping">
									<b-img
										src="http://static.kza666.com/kza/2022-06-09/406ce3a6-1bfc-4ac0-9490-8f7cc9401b8c"
										fluid>
									</b-img>
								</div>
								<div style="text-align: center;">
									<b-container class="bv-example-row product-img" >
										<b-row class="line-h">
											<b-col>
												<b-img
													src="http://static.kza666.com/kza/2022-06-09/b9774f15-2912-4c3d-8b34-62abd7202ca6"
													fluid></b-img>
												<p class="product-buy-name">C级防盗安全锁</p>
												<p class="product-buy-content">强度堪比防盗门</p>
											</b-col>
											<b-col>
												<b-img
													src="http://static.kza666.com/kza/2022-06-28/2b804b06-ef22-423f-9781-81faea9d5ecd"
													fluid></b-img>
												<p class="product-buy-name">智能交友</p>
												<p class="product-buy-content">一键匹配宠友</p>
											</b-col>
											<b-col>
												<b-img
													src="http://static.kza666.com/kza/2022-06-28/75dd5cac-4682-4bcd-b6ff-20245f5d01ee"
													fluid></b-img>
												<p class="product-buy-name">4G信号</p>
												<p class="product-buy-content">GPS多重定位</p>
											</b-col>
										</b-row>
									</b-container>
								</div>
							</div>
						</b-col>
						<b-col sm style="text-align: center;">
							<b-img src="http://static.kza666.com/kza/2022-06-09/5fdd1a95-1564-4a70-98e1-0dab5a46ecd0" fluid>
							</b-img>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div class="product-content-warp" >
			<b-container class="bv-example-row">
				<b-row align-h="around" align-v="center">
					<b-col sm class="mr-auto product-gary">
						<p><span class="product-gary-tips" style="color: #27C8B0;">即使</span>宠物认得回家的路</p>
						<p>也无法防止被陌生人牵走、被狗贩子惦记…</p>
					</b-col>
				</b-row>
				<b-row align-h="around" align-v="center" class="line-h-img">
					<b-col sm class="mr-auto">
						<b-img src="http://static.kza666.com/kza/2022-06-09/7279aacb-bc13-4675-8fb2-f39eb97130f8" fluid>
						</b-img>
					</b-col>
				</b-row>
				<b-row align-h="around" align-v="center">
					<b-col sm class="mr-auto phone-device-img">
						<b-img src="http://static.kza666.com/kza/2022-06-09/5dadd013-6f11-4d04-9096-7d8d360aa359" fluid>
						</b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
	<div class="product-content-warp" style="background-color: #f5f5f5;">
			<div>
				<p class="over-title-ch">以宠为本 智能守护</p>
				<p class="over-title-en-18 img-bottom"> PET ORIENTED INTELLIGENT GUARD</p>
			</div>
			<div>
				<b-container class="bv-example-row">
					<b-row align-h="around" align-v="center">
						<b-col sm class="mr-auto  guard-img" >
							<b-img src="http://static.kza666.com/kza/2022-07-29/3dbc9b99-4639-49df-bfbe-ebfa876ee1ac"
								fluid></b-img>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div class="product-content-warp" style="padding-top: 0;">
			<div class="product-app-top">
				<p class="over-title-ch">专属APP 解锁8大权益</p>
				<p class="over-title-en-18 img-bottom">EXCLUSIVE APP UNLOCKS & BENEFITS</p>
			</div>
			<div>
				<div class="product-benefits">
					<b-container class="bv-example-row product-benefits">
						<div class="product-two-show">
							<b-row >
								<b-col>
									<img width="90%" src="@/assets/product/app1.png">
								</b-col>
								<b-col>
									<img width="90%" src="@/assets/product/app2.png">
								</b-col>
								<div class="w-100" style="margin: 10px 0;"></div>
								<b-col>
									<img width="90%" src="@/assets/product/app3.png">
								</b-col>
								<b-col>
									<img width="90%" src="@/assets/product/app4.png">
								</b-col>
								<div class="w-100" style="margin: 10px 0;"></div>
								<b-col>
									<img width="90%" src="@/assets/product/app1.png">
								</b-col>
								<b-col>
									<img width="90%" src="@/assets/product/app2.png">
								</b-col>
								<div class="w-100" style="margin: 10px 0;"></div>
								<b-col>
									<img width="90%" src="@/assets/product/app3.png">
								</b-col>
								<b-col>
									<img width="90%" src="@/assets/product/app4.png">
								</b-col>
							</b-row>
						</div>
						<div  class="product-one-show">
							<b-row class="p-3 ">
								<b-col>
									<img  src="@/assets/product/app1.png">
								</b-col>
								<b-col>
									<img src="@/assets/product/app2.png">
								</b-col>
								<b-col>
									<img src="@/assets/product/app3.png">
								</b-col>
								<b-col>
									<img src="@/assets/product/app4.png">
								</b-col>
							</b-row>
							<b-row class="p-3">
								<b-col>
									<img  src="@/assets/product/app5.png">
								</b-col>
								<b-col>
									<img  src="@/assets/product/app6.png">
								</b-col>
								<b-col>
									<img  src="@/assets/product/app7.png">
								</b-col>
								<b-col>
									<img src="@/assets/product/app8.png">
								</b-col>
							</b-row>
						</div>
					</b-container>
				</div>
				<b-container class="bv-example-row">
					<b-row align-h="around" align-v="center">
						<b-col sm class="benefits-img">
							<b-img src="http://static.kza666.com/kza/2022-07-29/85a5c6dd-bf1b-4fd1-975c-360cdef9e7bd"
								fluid></b-img>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {

			}
		},
		methods: {
			goShopping() {
				let url ="https://mall.jd.com/index-12052223.html"
				window.open(url, "_blank")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.product-wrap {
		font-family: Microsoft YaHei;
		width: 100%;
		font-weight: bold;
	}

	//顶部的 购买页面
	.product-buy-top {
		background: url('../../assets/product/product-bg.png') no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		padding-top: 46px;
	}
	//购买 图标 更改
	.product-img{
		.col{
			padding-left: 0 !important;
		}
	}
	.product-buy-titel {
		font-size: 60px;
	}

	.product-buy-tips {
		font-size: 30px;
	}

	.product-buy-price {
		padding: 10px 0;
		font-size: 48px;
	}
	.price-f{
		font-size: 30px;
	}

	.product-buy-name {
		font-weight: bold;
		margin-top: 8px;
		font-size: 16px;
	}

	.product-buy-content {
		font-size: 16px;
		font-weight: bold;
	}
	.line-h{
		margin-top: 60px;
	}
	//总框架的 布局 上下距离
	.product-content-warp {
		padding-top: 100px;
		padding-bottom: 100px;
		text-align: center;
	}
	// 专属app顶部距离
	.product-app-top{
		padding-top: 100px;
	}
	.guard-titel {
		font-size: 48px;
	}

	.guard-tips {
		font-size: 18px;
		padding-bottom: 80px;
	}
	//即使宠物认路 样式
	.product-gary {
		p {
			font-size: 36px;
		}
	}
	// 放大即使两个字
	.product-gary-tips{
		font-size: 44px;
		font-weight: bold;
	}
	// 标题下面 与图片的距离
	.img-bottom{
		margin-bottom: 40px;
	}
	// pc端 专属app的图片显示
	.product-one-show{
		img {
			width: 90%;
		}
	}
	// 即使宠物认得回家的路 中间图片样式
	.line-h-img{
		margin-top: 60px;
		margin-bottom: 60px;
	}
	.product-benefits {
		margin-bottom: 60px;

	}
	// app权益 图片样式
	.benefits-img{
		.img-fluid {
			max-width: 90%;
		}
	}

	@media screen and(max-width:576px) {
		.product-one-show {
			display: none;
		}
		// 页面中的主图
		.img-fluid {
			max-width: 60%;
		}
	}
	
	@media screen and(min-width:577px) {
		.product-two-show {
			display: none;
		}
	}
	@media only screen and(max-width:991px) {
		// 购买 防丢防盗 标题
		.product-buy-titel {
			font-size: 34px;
			font-weight: bold;
		}
		// 项圈说明
		.product-buy-tips {
			font-size: 18px;
			font-weight: bold;
		}
		// 价格上下行距
		.product-buy-price {
			font-size: 28px;
		}
		//购买 图标 更改
		.product-img{
			padding-top: 10px;
			.img-fluid {
				max-width: 50%;
			}
		}
		// 金钱符号 样式
		.price-f{
			font-size: 18px;
		}
		.product-buy-name {
			font-size: 12px;
		}
		//宠物认得路的 文字样式
		.product-gary {
			p {
				font-weight: bold;
				font-size: 16px;
			}
		}
		// 放大即使两个字
		.product-gary-tips{
			font-size: 22px;
		}
		.product-buy-content {
			font-size: 12px;
		}
		// 购买图标说明 行距 样式
		.line-h{
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.guard-titel {
			font-size: 28px;
		}
		.go-shopping .img-fluid {
		    max-width: 40%;
		}
		.guard-tips {
			font-size: 14px;
			padding-bottom: 50px;
		}
		//以宠为本内容
		.product-content-warp {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		// 专属app 顶部距离
		.product-app-top{
			padding-top: 60px;
		}
		// 即使宠物认得回家的路 中间图片样式
		.line-h-img{
			margin-top: 30px;
			margin-bottom: 30px;
			.img-fluid {				max-width: 95%;			}
		}
		// 宠不失 爱不失 图片
		.phone-device-img{
			.img-fluid {
				max-width: 70%;
			}
		}
		// app权益 图片样式 
		.benefits-img {
			.img-fluid {
				max-width: 95%;
			}
		}
		//以宠为本
		.guard-img{
			.img-fluid {
				max-width: 100%;
			}
		}
	}
</style>
