<template>
	<div class="header-warp">
		<div class="navbar-top">
			<transition class="active">
				<b-navbar toggleable="lg">
					<b-navbar-toggle target="nav-collapse" > </b-navbar-toggle>
					<b-navbar-brand>
						<img class="header-logo" src="../../assets/layout/logo.png" />
					</b-navbar-brand>
					<!-- 适应手机大小 样式 -->
					<!-- 顶部导航栏选中的模快 -->
					<b-collapse id="nav-collapse" is-nav>
						<!--靠右边 -->
						<b-navbar-nav class="ml-auto">
							<b-nav-item to="/home" >
								<div class="nav-item" :class="{'nave-is-active':url=='/home'}">
									<span>首页</span>
									<div v-show="url=='/home'" class="nav-active"></div>
								</div>
							</b-nav-item>
							<b-nav-item to="/product">
								<div class="nav-item" :class="{'nave-is-active':url=='/product'}">
									<span>智能产品</span>
									<div v-show="url=='/product'" class="nav-active"></div>
								</div>
							</b-nav-item>
							<b-nav-item to="/intrduce">
								<div class="nav-item" :class="{'nave-is-active':url=='/intrduce'}">
									<span>APP介绍</span>
									<div v-show="url=='/intrduce'" class="nav-active"></div>
								</div>
							</b-nav-item>
							<b-nav-item to="/about">
								<div class="nav-item" :class="{'nave-is-active':url=='/about'}">
									<span>关于我们</span>
									<div v-show="url=='/about'" class="nav-active"></div>
								</div>
							</b-nav-item>
							<b-nav-item to="/service">
								<div class="nav-item nav-item-bottom" :class="{'nave-is-active':url=='/service'}">
									<span>服务与支持</span>
									<div v-show="url=='/service'" class="nav-active"></div>
								</div>
							</b-nav-item>
						</b-navbar-nav>
						<div class="download-code">
							<img class="code-img" src="../../assets/layout/code.png" />
							<img class="download-img" src="../../assets/layout/downCode.png" />
							<div class="download-tips">扫码下载app</div>
						</div>
					</b-collapse>
				</b-navbar>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				url: "",
			}
		},
		watch: {
			"$route.path"(n, o) {
				this.url = n;
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
				// 兼容IE
				window.scrollTo(0, 0);
			}
		},
		mounted() {
			this.url = this.$route.path;
		},
		methods: {
		}
	}
</script>

<style lang="scss">
	.header-warp {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
	}

	.navbar-top {
		background-color: #fff;
		height: 90px;
		width: 100%;
	}
	// 光标移动方向样式
	.nav-item {
		cursor: pointer;
		position: relative;
		font-size: 16px;
		padding:0  10px;
		color: #222;
		font-weight: 400;
		span {
			height: 20px;
		}
	}

	// 选中导航栏字体样式
	.nave-is-active {
		color: #27C8B0;
	}

	// 光标样式
	.nav-active {
		position: absolute;
		top: 35px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 30px;
		height: 6px;
		background: #27C8B0;
		border-radius: 3px;
	}

	//头部logo
	.header-logo {
		margin: 24px 0;
		margin-left: 40px;
		width: 120px;
		height: 40px;
	}

	// 头部二维码扫描
	.download-code {
		width: 80px;
		text-align: center;
		cursor: pointer;

		.download-tips {
			display: none;
		}

		.code-img {
			width: 20px;
			height: 20px;
		}

		.download-img {
			display: none;
		}

		&:hover {
			.code-img {
				display: none;
			}

			.download-img {
				display: block;
				margin-left: 6px;
				width: 68px;
				height: 68px;

			}

			.download-tips {
				display: block;
				font-size: 12px;
				font-family: Microsoft YaHei;
			}
		}
	}

	// 整体导航栏样式
	.navbar {
		background-color: #fff;
		z-index: 9999;
		padding: 0 1rem !important;

	}

	// 导航栏文字样式
	.navbar-brand {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.navbar-light .navbar-nav .nav-link {
		color: #222;
		font-size: 16px;
		font-family: Microsoft YaHei;
	}

	@media screen and(max-width:991px) {
		.nav-active {
			display: none;
		}

		.download-code {
			display: none;
		}

		.nav-item-bottom{
			padding-bottom: 20px;
		}
	}


		@media screen and(min-width:1420px) {
			.navbar-top {
				padding: 0 12%;
			}
		}
		@media screen and(min-width:1312px) and (max-width:1411px) {
			.navbar-top {
				padding: 0 8%;
			}
		}
		@media screen and(min-width:1211px) and (max-width:1311px) {
			.navbar-top {
				padding: 0 6%;
			}
		}
		@media screen and(min-width:1111px) and (max-width:1210px){
			.navbar-top {
				padding: 0 4%;
			}
		}
		@media screen and(min-width:1000px) and (max-width:1110px){
			.navbar-top {
				padding: 0 2%;
			}
		}
	a:hover {
		text-decoration: none !important;
	}
</style>
