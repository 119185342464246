import Vue from 'vue'
import VueRouter from 'vue-router'
// 主布局
import Layout from '../layout/index.vue'

// 首页
import Home from '../views/home/index.vue'
// 智能产品
import Product from '../views/product/index.vue'
// App介绍
import Intrduce from '../views/introduce/index.vue'
// 关于我们
import About from '../views/about/index.vue'

//新闻详请 主框架页面
import News from '../views/about/newsDetail.vue'
import articlesOne from '../views/about/articles/articlesOne.vue'
import articlesTwo from '../views/about/articles/articlesTwo.vue'
import articlesThree from '../views/about/articles/articlesThree.vue'



// 服务与支持
import Service from '../views/service/index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'layout',
	component: Layout,
	redirect: '/home',
	children: [{
		path: '/home',
		name: '首页',
		component: Home,
	},
	{
		path: '/product',
		name: '智能产品',
		component: Product,
	},
	{
		path: '/intrduce',
		name: 'APP介绍',
		component: Intrduce,
	},
	{
		path: '/about',
		name: '关于我们',
		component: About,
	},
	{
		path: '/service',
		name: '服务与支持',
		component: Service,
	},
	{
		path: '/news',
		name: 'News',
		component: News,
		redirect: '/articlesOne',
		children: [{
			path: '/articlesOne',
			name: 'articlesOne',
			component: articlesOne,
		},
		{
			path: '/articlesTwo',
			name: 'articlesTwo',
			component: articlesTwo,
		},
		{
			path: '/articlesThree',
			name: 'articlesThree',
			component: articlesThree,
		},
		]

	},
	]
},
{
	path: '/market-promotion',
	name: 'market-promotion',
	component: () => import("../views/market-promotion/index.vue"),
	desc: { title: "商城推广页面" }
},
]

const router = new VueRouter({

	routes
})

export default router
