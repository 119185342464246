<!-- 由 lz 创建于 2022-10-21 星期五 -->
<template>
  <div v-show="show && valid" class="activity" @scroll.stop.prevent>
    <div class="pa_wrap animate__animated animate__zoomIn">
      <img :src="bg_pic" class="pa_w_bg" @click="jumpPc" />
      <img :src="close_btn" class="pa_w_close" @click="close('.pa_wrap')" />
    </div>
    <div class="app_wrap animate__animated animate__zoomIn">
      <img :src="bg_pic" class="aw_bg" @click="jumpApp" />
      <img :src="close_btn" class="aw_close" @click="close('.app_wrap')" />
    </div>
  </div>
</template>

<script>
import animate from "@/style/animate.js";
export default {
  name: "activity",

  mixins: [],

  components: {},

  props: {
    start: {},
    end: {
      default: () => {
        return new Date();
      }
    }
  },

  data() {
    return {
      show: true,
      bg_pic: "https://static3.kza666.com/activity/bg1.jpg",
      close_btn: "https://static3.kza666.com/activity/close_btn.png",
      pc_url: "http://mohamoha.tmall.com/index.htm",
      app_url:
        "https://shop.m.taobao.com/shop/shop_index.htm?sellerId=2214839175831&shopId=238833627&inShopPageId=356276292&pathInfo=shop/index2"
    };
  },

  computed: {
    valid() {
      let start = this.start ? new Date(this.start) : new Date();
      let end = new Date(this.end);
      let now = new Date();
      console.log(start, end, now);
      let bool = now >= start && now <= end;
      return bool;
    }
  },

  watch: {},

  created() {},

  mounted() {},

  destroyed() {},

  methods: {
    close(el) {
      animate(el, "zoomOut", "0.5s").then(() => {
        this.show = false;
      });
    },
    jumpPc() {
      location.href = this.pc_url;
    },
    jumpApp() {
      location.href = this.app_url;
    }
  }
};
</script>

<style lang='scss' scoped>
.activity {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    .pa_wrap {
      display: none;
    }
    .app_wrap {
      display: block;
      position: relative;
      width: 90%;
      top: -2%;
      .aw_bg {
        width: 100%;
        border-radius: 4%;
      }
      .aw_close {
        display: block;
        position: absolute;
        width: 10%;
        bottom: -15%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 769px) {
    .app_wrap {
      display: none;
    }
    .pa_wrap {
      display: block;
      position: relative;
      top: 2%;
      width: 42%;
      .pa_w_bg {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 4%;
        cursor: pointer;
      }
      .pa_w_close {
        width: 7%;
        height: auto;
        object-fit: cover;
        position: absolute;
        top: -9%;
        right: -7.5%;
        cursor: pointer;
      }
    }
  }
}
</style>