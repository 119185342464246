<template>
	<div class="footer-warp" v-show="showFooter">
		<div style="text-align: center;">
			<div>
				<div class="footer-warp-download">
					<div>
						<span @click="iosDownload">
							<b-img class="down-but-img"
								src="http://static.kza666.com/kza/2022-06-07/5e9412b8-5ba4-45e0-9e9e-dbc7e4bd0aad" fluid
								alt="Fluid-grow image"></b-img>

						</span>
						<span @click="androidDownload">
							<b-img class="down-but-img"
								src="http://static.kza666.com/kza/2022-06-07/75466b7a-76af-48f0-a0dd-88cda229d753" fluid
								alt="Fluid-grow image"></b-img>

						</span>

					</div>
					<div class="download-code-div">
						<img class="download-down-logo" src="../../assets/layout/footerDown.png" />
						<img class="download-code" src="../../assets/layout/downCode.png" />
					</div>
				</div>
				<div>
					<b-container class="bv-example-row">
						<b-row>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-07/c12efc88-dac2-449a-8b8e-eefb814ea91e"
									fluid alt="Fluid-grow image"></b-img>
								<div>官方公众号</div>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-07-25/04498cc7-2745-47e0-bb64-909c13dc253e"
									fluid alt="Fluid-grow image"></b-img>
								<div>官方客服微信</div>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-07/151f512b-dd78-40e1-9c2b-52afc1ae161b"
									fluid alt="Fluid-grow image"></b-img>
								<div>官方旗舰店</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
			<div class="footer-content-record">
				<div style="cursor: pointer;">
					Copyright © 2021-2022 长沙科致安科技信息有限公司<span @click="tobeian()"> 湘ICP备2021002628号-3 </span>
					<div>
							<span  @click="goPrivacy">隐私政策</span> |  <span  @click="goUserAgreement">用户服务协议</span> | 开发者:长沙科致安科技信息有限公司 | 版本号:1.0
					</div>
					<div>
						<span @click="linkAbout('contactUs')" style="text-decoration: underline;">
							违法不良信息投诉举报</span>
						官方电话：400-100-3735
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import downLoadUtil from '@/utils/downloadApp.js'
	export default {
		data() {
			return {
				showFooter: true, //true 显示出来 false隐藏起来
			}
		},
		watch: {

		},
		mounted() {

		},
		methods: {
			//用户协议
			goUserAgreement() {
				let url ="http://mohamh.com/protocol/#/userAgreement"
				window.open(url, "_blank")
			},
			// 隐私政策
			goPrivacy() {
				let url ="http://mohamh.com/protocol/#/privacyPolicy"
				window.open(url, "_blank")
			},
			//描点定位到 联系我们 信息那里
			tobeian() {
				window.location.href = "https://beian.miit.gov.cn/"
			},
			iosDownload() {
				downLoadUtil.iosApp()
			},
			androidDownload() {
				downLoadUtil.androidApp()
			},
			linkAbout(val) {
				if (this.$route.fullPath != "/about") {
					localStorage.setItem("contact", val);
					this.$router.push('/about');
				} else {
					return;
				}

			}
		}
	}
</script>

<style lang="scss" scoped>
	.footer-warp {
		background: #000000;
		text-align: center;
		display: flex;
		justify-content: center;
		color: #999999;
		line-height: 36px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
	}

	// 下载按钮和扫码样式
	.footer-warp-download {
		padding-top: 70px;
		padding-bottom: 40px;
		// height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;

		// 扫码下载样式 小logo
		.download-code-div {
			text-align: left;
			cursor: pointer;
			position: relative;

			.download-code {
				position: absolute;
				top: -40px;
				width: 110px;
				height: 110px;
				display: none;
			}

			&:hover {
				.download-code {
					display: block;
				}
			}
		}


	}

	//下载按钮滑过样式
	.down-but-img {
		margin-right: 20px;

		&:hover {
			box-shadow: 1px 1px 1px 1px #fff;
			border-radius: 5px;
		}
	}


	// 客服公众号
	.footer-content-code {
		margin-top: 10px;
		margin-bottom: 29px;
		color: #999999;

	}

	// 底部解释说明
	.footer-content-record {
		padding: 12px  10px;
		padding-bottom: 28px;
	}

	//小机型隐藏下载按钮
	@media screen and (max-width:480px) {
		.download-code-div {
			display: none;
		}
	}

	//大屏幕显示下载 二维码
	@media screen and(min-width:481px) {
		.download-code-div {
			display: block;
		}

	}

	@media only screen and(max-width:991px) {
		.footer-warp {
			font-size: 12px;
		}
	}
</style>
