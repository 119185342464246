<template>
	<div class="introduce-wrap">
		<div class="introduce-app-down  introduce-bottom">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col lg="7" sm style="color: #fff;" class="app-font-algin">
						<div class="app-down-title">
							<p>
								宠物安全健康、趣味养宠生活
							</p>
							<p style="color: #06F5D5;">
								1个APP全搞定！
							</p>
						</div>
						<p class="app-down-tips">
							全国宠友都在玩，一起来体验萌趣社交吧！
						</p>
						<div class="app-down-but">
							<div class="app-down-warp" @click="iosDownload">
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/82f7d2a6-4902-4bf9-861e-193f26a07f5c"
									fluid></b-img>
							</div>
							<div @click="androidDownload">
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/40f85a7e-3121-462a-ab5b-5d42f3985ca4"
									fluid></b-img>
							</div>
						</div>
						<div class="app-download-code">
							<b-img src="http://static.kza666.com/kza/2022-06-08/61bf9e84-81c0-4972-ba03-39182bb61659"
								fluid></b-img>
							<p class="down-tips">扫码下载陌哈陌哈</p>
						</div>
					</b-col>
					<b-col sm style="text-align: center;" class="app-img-bottom">
						<b-img src="http://static.kza666.com/kza/2022-06-07/9feaf3c2-24f4-4698-94d4-a6a7032e1850" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="introduce-app-detail">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="img-right">
						<b-img src="http://static.kza666.com/kza/2022-06-07/02ee1542-3520-41c8-b83b-da28a2890590" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
					<b-col order="1" class="img-down" sm>
						<b-img src="http://static.kza666.com/kza/2022-06-07/02ee1542-3520-41c8-b83b-da28a2890590" fluid
							alt="Fluid-grow image"></b-img>
					</b-col>
					<b-col sm class="app-font-algin algin-right">
						<div>
							<div>
								<p class="over-title-ch">智能项圈管理</p>
								<p class="over-title-en-18">FUNCTION MANAGEMENT</p>
							</div>
							<div class="app-content-blue">
								<p>爱宠运动轨迹、遛宠模式、</p>
								<P>一键精准寻宠、趣味语音互动…</P>
							</div>
							<div class="app-content-black">
								<p>软硬件完美交互，功能便捷管理</p>
							</div>
						</div>
					</b-col>

				</b-row>
			</b-container>
		</div>
		<div class="introduce-app-detail" style="background-color: #F5F5F5;">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="app-font-algin algin-left">
						<div>
							<div>
								<p class="over-title-ch">宠物健康服务</p>
								<p class="over-title-en-18">PET HEALTH SERVICE</p>
							</div>
							<div class="app-content-blue">
								<p>宠物健康、萌宠学院</p>
								<p>多元智能化服务平台</p>
							</div>
							<div class="app-content-black">
								<p>守护爱宠成长健康</p>
							</div>
						</div>
					</b-col>
					<b-col sm>
						<b-img src="http://static.kza666.com/kza/2022-07-29/97c86a86-527d-42b6-8621-d13078c6cd55" fluid
							alt="fluid-grow	 image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="introduce-app-detail">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="img-right">
						<b-img src="http://static.kza666.com/kza/2022-06-07/fe417312-e6de-4d35-ac4a-be700e6a3bf7"
							fluid-grow alt="fluid-grow	 image"></b-img>
					</b-col>
					<b-col order="1" class="img-down" sm>
						<b-img src="http://static.kza666.com/kza/2022-06-07/fe417312-e6de-4d35-ac4a-be700e6a3bf7"
							fluid-grow alt="fluid-grow	 image"></b-img>
					</b-col>
					<b-col sm class="app-font-algin algin-right">
						<div>
							<div>
								<p class="over-title-ch">探索宠圈动态</p>
								<p class="over-title-en-18">EXPLORE PET CIRCLES</p>
							</div>
							<div class="app-content-blue">
								<p>专属“宠友圈”</p>
								<p>记录爱宠成长点滴，看见世界宠友动态</p>
							</div>
							<div class="app-content-black">
								<p>宠咖社区，达人养宠经验分享 </p>
							</div>
						</div>
					</b-col>

				</b-row>
			</b-container>
		</div>
		<div class="introduce-app-detail" style="background-color: #F5F5F5;">
			<b-container>
				<b-row align-h="around" align-v="center">
					<b-col sm class="app-font-algin algin-left">
						<div>
							<div>
								<p class="over-title-ch">趣味萌宠社交</p>
								<p class="over-title-en-18">FUNNY PETS DATING</p>
							</div>
							<div class="app-content-blue">
								<p>宠物在线相亲、寻找同类玩伴</p>
								<p>陌生宠友智能匹配，话题不断，畅聊不停 </p>
							</div>
							<div class="app-content-black">
								<p>携宠交友，更轻松更纯粹 </p>
							</div>
						</div>
					</b-col>
					<b-col sm>
						<b-img src="http://static.kza666.com/kza/2022-06-07/01dc89a5-6ad2-4f3b-b7de-ce23abd1df64"
							fluid-grow alt="fluid-grow  image"></b-img>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	import downLoadUtil from '@/utils/downloadApp.js'
	export default {

		data() {
			return {

			}
		},
		methods: {
			iosDownload() {
				downLoadUtil.iosApp()
			},
			androidDownload() {
				downLoadUtil.androidApp()
			}

		}
	}
</script>

<style lang="scss" scoped>
	.introduce-wrap {
		width: 100%;
		font-family: Microsoft YaHei;
	}

	// app下载介绍 第一张
	.introduce-app-down {
		background: url('../../assets/introduce/app-bg.png') no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
	}

	.introduce-bottom {
		padding: 76px 0;
	}

	// app下载主标题
	.app-down-title {
		// font-family: Source Han Sans CN;
		font-family: Microsoft YaHei;
		font-weight: bold;

		p {
			font-size: 48px;
		}
	}

	// app下载说明
	.app-down-tips {
		margin: 5px 0 20px;
		font-size: 24px;
	}

	//下载按钮的样式
	.app-down-but {
		display: flex;
		flex-wrap: wrap;

		.app-down-warp {
			margin-bottom: 5px;
		}
	}

	.app-download-code {
		margin-top: 18px;
		margin-bottom: 10px;
	}

	// 二维码下载提示文字
	.down-tips {
		line-height: 30px;
		font-size: 14px;
		font-weight: 400;

	}

	// app 内容介绍
	.introduce-app-detail {
		display: flex;
		align-items: center;
		text-align: center;

	}

	.img-down {
		display: none;
	}

	.app-content-blue {
		color: #26C8B0;
		padding: 38px 0;

	}




	@media screen and(max-width:576px) {
		//下载按钮变大
		.app-down-but {
			justify-content: center;
			.img-fluid {
				max-width: 85%;
			}
		}

		.app-font-algin {
			text-align: center;
		}
	}

	@media screen and(min-width:577px) {
		.algin-right {
			text-align: right;
		}

		.algin-left {
			text-align: left;
		}
	}

	@media screen and(min-width:769px) {
		.app-down-warp {
			margin-right: 20px;
		}
	}

	@media only screen and(max-width:991px) {
		.introduce-app-down {
			background-size: cover;
		}

		// app下载主标题
		.app-down-title {
			font-family: Microsoft YaHei;

			p {
				font-weight: bold;
				font-size: 26px;
			}
		}

		.img-fluid {
			max-width:80%;
		}

		// app下载说明
		.app-down-tips {
			margin: 1.5vw 0;
			font-size: 16px;
		}

		.app-down-but {
			margin: 20px;
		}

		//隐藏二维码下载页面
		.app-download-code {
			display: none;
		}

		// app图片 大小
		.app-img-bottom {
			.img-fluid {
				max-width: 90%;
			}
		}


		.img-down {
			display: block;
		}

		.img-right {
			display: none;
		}

		.app-content-blue {
			padding: 18px 0;

		}

		.introduce-app-detail {
			padding-top: 60px;
			padding-bottom: 30px;
		}
		.introduce-bottom {
			padding: 50px 0;
		}

	}
</style>
