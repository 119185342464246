<template>
	<div class="service-wrap">
		<div class="service-bg-wrap">
			<div style="text-align: center;color: #fff;">
				<div class="service-bg-title">
					陌哈陌哈 · 全国售后服务中
				</div>
				<div class="service-bg-tips">
					<div class="bg-tips-one">
						为提升用户体验，陌哈陌哈将为您提供专业、便捷的售后服务
					</div>
					<div class="bg-tips-two">
						<div>为提升用户体验</div>
						<div>陌哈陌哈将为您提供专业、便捷的售后服务</div>
					</div>
				</div>
			</div>
		</div>
		<div class="service-customer service-customer-botton-0">
			<div style="text-align: center;width: 100%;">
				<div class="online-bottom">
					<p class="over-title-ch">全国客户服务</p>
					<p class="over-title-en-18">NATIONAL CUSTOMER SERVICE</p>
				</div>
				<b-container>
					<b-row align-h="around">
						<b-col sm class="mr-auto p-3">
							<b-img style="width: 100%;"
								src="http://static.kza666.com/kza/2022-07-26/5864c9f0-a03f-4ff6-a80f-9c36bee27564"
								fluid></b-img>
							<p class="customer-title">服务网点遍布全国</p>
							<div class="customer-title-tips">
								<p>覆盖全国地级市 </p>
								<p> 快捷解决产品问题</p>
							</div>
						</b-col>
						<b-col sm class="mr-auto p-3">
							<b-img style="width: 100%;"
								src="http://static.kza666.com/kza/2022-06-08/dfc0a2ba-6b6e-43d7-bcda-4e829fe01d77"
								fluid></b-img>
							<p class="customer-title">24小时在线服务</p>
							<div class="customer-title-tips">
								<p>贴心客服服务</p>
								<p>在线解答用户问题</p>
							</div>
						</b-col>
						<b-col sm class="mr-auto p-3">
							<b-img style="width: 100%;"
								src="http://static.kza666.com/kza/2022-06-08/efcea453-de25-4ea9-a9cf-822e9eaf7199"
								fluid></b-img>
							<p class="customer-title">强大售后服务保障</p>
							<div class="customer-title-tips">
								<p>合法保障用户权益</p>
								<p>产品质量品质保障</p>
							</div>
						</b-col>
					</b-row>
				</b-container>

			</div>
		</div>
		<div class="service-customer" style="background-color: #F5F5F5;">
			<div class="service-contant " style="text-align: center;width: 100%;">
				<div class="online-bottom">
					<p class="over-title-ch">全国线上服务渠道</p>
					<p class="over-title-en-18">NATIONAL ONLINE SERVICE CHANNEL</p>
				</div>
				<div class="contant-two-show">
					<b-container class="bv-example-row ">
						<b-row>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/bb9beb2d-2243-4671-9e1d-72e768bfc83a"
									fluid></b-img>
								<p style="margin-top: 10px;">官方热线</p>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/12bfa2f5-45da-49d3-8731-611f6df31d55"
									fluid></b-img>
								<p  style="margin-top: 10px;">售前客服</p>
							</b-col>
							<div class="w-100" style="margin: 10px 0;"></div>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/e7c8a248-ad16-4342-89ec-a696b51f30fe"
									fluid></b-img>
								<p  style="margin-top: 10px;">售后客服</p>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/73590845-95a4-4ae7-afe8-9df329eedf92"
									fluid></b-img>
								<p  style="margin-top: 10px;">其它咨询</p>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<div class="contant-show">
					<b-container>
						<b-row align-h="around" >
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/bb9beb2d-2243-4671-9e1d-72e768bfc83a"
									fluid></b-img>
								<p>官方热线</p>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/12bfa2f5-45da-49d3-8731-611f6df31d55"
									fluid></b-img>
								<p>售前客服</p>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/e7c8a248-ad16-4342-89ec-a696b51f30fe"
									fluid></b-img>
								<p>售后客服</p>
							</b-col>
							<b-col>
								<b-img
									src="http://static.kza666.com/kza/2022-06-08/73590845-95a4-4ae7-afe8-9df329eedf92"
									fluid></b-img>
								<p>其它咨询</p>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.service-wrap {
		width: 100%;
		// font-family: Source Han Sans CN;
		font-family: Microsoft YaHei;
	}

	.service-bg-wrap {
		background: url('../../assets/service/service-bg.png') no-repeat;
		// background-size: 100% 100%;
		background-size: cover;
		padding: 120px 0;
		height: 520px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.service-bg-title {
		font-size: 48px;
		font-weight: bold;
	}

	.service-bg-tips {
		font-size: 24px;
		font-weight: 400;
	}
	.bg-tips-two{
		display: none;
	}
	// 下面内容的布局
	.service-customer {
		padding: 110px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	//服务展示内容 中文标题
	.service-tilte-ch {
		font-size: 48px;
		font-weight: bold;
	}

	//服务展示内容 英文标题
	.service-tilte-en {
		font-weight: bold;
		padding-bottom: 80px;
	}

	//客服服务标题
	.customer-title {
		margin-top: 20px;
		font-size: 24px;
		font-weight: bold;
	}
	.customer-title-tips{
		p{
			font-size: 16px;
		}
	}
	.online-bottom{
		margin-bottom: 40px;
	}

	.contant-show {
		p{
			margin-top: 10px;
		}
		.img-fluid {
				max-width: 60%;
			}
	}
	@media screen and(max-width:576px) {
		.contant-show {
			display: none;
		}

		// 页面中的主图
		.service-contant .img-fluid {
			max-width: 70%;
		}
	}

	@media screen and(min-width:577px) {
		.contant-two-show {
			display: none;
		}
	}
	
	@media only screen and(max-width:991px) {
		.service-bg-wrap {
			background-size: cover;
			padding: 80px 0;
			height: 320px;
		}
		.bg-tips-one{
			display: none;
		}
		.bg-tips-two{
			display: block;
		}
		//客服服务标题
		.customer-title {
			margin-top: 20px;
		}
		.customer-title-tips{
			margin-bottom: 30px;
		}
		// 下面布局
		.service-customer {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		// 全国服务下面距离样式
		.service-customer-botton-0{
			padding-bottom: 12px;
		}
		.service-bg-title {
			font-size: 28px;
		}
		.service-bg-tips {
			font-size: 16px;
		}
		.service-tilte-ch {
			font-size: 28px;
			font-weight: bold;
		}
		//服务展示内容 英文标题
		.service-tilte-en {
			font-weight: bold;
			padding-bottom: 50px;
		}
		
	}
</style>
