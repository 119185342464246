<template>
	<div class="articles-warp">
		<div class="details-content">
			<div class="details-content-title">拒绝宠物丢失流浪：选对宠物防丢智能产品，能护它一生安全</div>
			<div class="details-content-time">发布于 2022-05-25 17:51</div>
			<div>
				对于许多铲屎官来说，养一只宠物就好像亲手带大一个小孩，尤其是狗狗这样的孩子，和狗狗相处久了，它会毫无保留地爱你，也深深依赖着你。狗狗对许多人来说已经不仅仅是宠物了，更是需要我们守护一生的家人。
			</div>
			<div>
				可如今宠物丢失的问题依然层出不穷，且被找回的概率很低。在宠物智能安全时代，选对宠物防丢智能产品，就显得尤为关键。
			</div>
			<div>
				<b>国家认证——宠不失宠物智能宠物项圈</b>
			</div>
			<div>
				这款名为宠不失的宠物智能宠物项圈，具备国家专利级<b>(实用新型专利：ZL202121405274.0</b>研发技术，独创的物理防丢防拆设计，让它在宠物智能安全市场占据一席之地。同时，它具备智能、预警、监护等多重防丢保障，是目前市场上综合实力靠前的宠物防丢智能产品，养宠物的铲屎官可随着这篇文章深入了解。
			</div>
			<div class="img-div">
				<img style="max-height:250px;min-width: 300px;width: 60%;" :src="imgOne">
			</div>
			<div>
				<b>
					自研物理防丢防偷技术
				</b>
			</div>
			<div>
				为了解决定位器丢失导致无法定位爱宠位置的难题宠不失智能宠物项圈成功研发了具备防拆防丢的宠物项圈。项圈只有主人能打开，能防止人为拆卸、宠物偷盗等危险发生，做到了物理上的实时有效定位宠物位置，是必要且有保障的安全升级！
			</div>
			<div>
				<b>
					精准智能防丢技术
				</b>
			</div>
			<div>
				宠不失智能宠物项圈选用的是基于真4G的多重定位技术（WIFI+LBS+AGPS+北斗），已经被淘汰的2G定位技术这里不多作解释，至于5G定位，用于小型定位里来说研发成本更高、续航要求也更高，所以4G技术是目前发展较为稳定成熟的定位技术。
			</div>
			<div>
				再结合多重定位技术，宠不失智能宠物项圈几乎覆盖了各种场景，从山川草原到地下室室内，都能实时精确追踪宠物位置，且精度高达三米，不怕找不回丢失的爱宠。
			</div>
			<div class="img-div">
				<img style="max-height:400px;width: 300px;" :src="imgTwo">
			</div>
			<div>
				<b>
					危险预警防丢技术
				</b>
			</div>
			<div>
				宠不失智能宠物项圈特别研发了一款专属APP——陌哈陌哈。用户在便捷查看定位器电池使用情况、宠物运动轨迹的同时，能更好地保护爱宠的出行安全。在电子围栏里设置爱宠安全玩耍区域，一旦超出范围，APP会立马报警提醒，一定程度上加快找回宠物的效率，安全性能大大提升。
			</div>
			<div>
				除此之外，还增加了趣味互动的语音功能，狗狗独自在家在外玩耍的时候，主人可通过手机向爱宠远程发送指令，趣味十足。
			</div>
			<div>
				<b>
					全方位监护防丢
				</b>
			</div>
			<div>
				宠不失智能宠物项圈支持一个设备多人分享，全家都能在手机上对宠物进行看护，多方监护更安全，更放心；同时，宠不失为每一只宠物开启了防丢失保障计划，一旦发生因产品质量问题而导致的宠物丢失，宠不失都将对宠主进行赔偿服务！
			</div>

		</div>
		<div class="details-link">
			<div>
				<router-link to="/articlesTwo">上一篇：狗狗爱乱跑？四重防丢科技，精确追踪实时位置，跑再远也能找回！</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				imgOne:"http://static.kza666.com/kza/2022-05-25/02acbdd5-dee0-47f1-9cca-a8ca0584860e",
				imgTwo:"http://static.kza666.com/kza/2022-05-25/9c2609f0-0367-4a56-9841-5e92c4bf2de1",
			}
		}
	}
</script>

<style lang="scss" scoped>
	.articles-warp {
		width: 100%;
		text-align: justify;
		word-break: break-all;
	}

	.details-content {
		font-family: Microsoft YaHei;
		font-weight: 400;

		>div {
			line-height: 30px;
			margin: 20px 0;
		}

		.img-div {
			margin: 20px 0;
			text-align: center;

			img {
				margin: 5px auto;
				cursor: pointer;
			}
		}

		.details-content-title {
			font-size: 32px;
			font-weight: 600;
			line-height: 48px;
		}

		.details-content-time {
			margin: 26px 0;
			font-size: 16px;
			color: #999999;
			line-height: 12px;
		}
	}
</style>
