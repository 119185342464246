<template>
	<div class="articles-warp">
		<div class="details-content">
			<div class="details-content-title">狗狗爱乱跑？四重防丢科技，精确追踪实时位置，跑再远也能找回！</div>
			<div class="details-content-time">发布于 2022-05-24 17:51</div>
			<div>
				随着科学养宠的概念不断深入，宠物智能产品越来越深受年轻铲屎官的喜爱。从喂食、喂水到铲屎、洗护烘干，当今宠物主们可以说是充分利用了科技智能，来解放自己的双手。
			</div>
			<div class="img-div">
				<img style="max-height:400px;width:300px;" :src="img">
			</div>
			<div>
				宠物如家人，没有一个铲屎官不愿意为爱宠的生活“买单”。其中，铲屎官重视与关心的宠物安全，也在“宠圈科技”掀起了一股热潮。今天为大家介绍的便是一款做到有效防止宠物丢失的宠物智能定位项圈——宠不失。
			</div>
			<div>
				<b>四重防丢保障，有效防止宠物丢失</b>
			</div>
			<div>
				经过反复调研与试验，从四个方面入手研发，宠不失智能宠物项圈综合了市面上目前发展成熟稳定的定位技术，也加入了荣获国家专利
				<b>
					(实用新型专利：ZL202121405274.0)
				</b>
				的自主研发设计，全方位守护爱宠出行安全。
			</div>
			<div>
				<b>一重：物理防丢</b>
			</div>
			<div>
			宠不失智能宠物项圈自主研发专利级(实用新型专利：ZL202121405274.0)防拆设计，项圈仅宠主可打开，拥有千位机械密码锁，陌生人无法打开；项圈内嵌φ3钢丝，能一定程度上阻止蛮力拉扯。物理加固，有效防止宠物偷盗。
			</div>
			<div>
				<b>二重：智能防丢</b>
			</div>
			<div>
				宠不失智能宠物项圈集合了基于真4G的多重定位技术，强信号、低延迟，能满足各种场景所需。不管爱宠是在山区、闹市、还是隐蔽的小街小巷，都能准确追踪其位置，最高精度可达3米，智能实时定位，不怕爱宠乱跑。
			</div>
			<div>
				<b>三重：预警防丢</b>
			</div>
			<div>
				为更好监护爱宠安全，宠不失智能宠物项圈为宠主搭配了专属APP——【陌哈陌哈】。宠主可在APP上为爱宠规划安全玩耍区域，爱宠一旦超出安全范围，APP则预警提醒，实时监控宠物出行安全。
			</div>
			<div>
				<b>四重：监护防丢</b>
			</div>
			<div>
				宠不失智能宠物项圈支持多家长看护模式，一个设备可以分享给全家庭管理，多一份关心则更多一重保障，多方监护更防丢。
			</div>
			<div>
				宠不失智能宠物项圈除了拥有四重防丢保障科技之外，在防水、舒适度上也同样做到了细致。设备为IP67级深度防水设计，雨天佩戴外出毫无压力；项圈也根据每一只毛孩子的身材体积量身定制，亲肤硅胶佩戴更舒适！
			</div>
			<div>
				选择一款合适的宠物防丢失神器，就等于为爱宠的成长安全加上了牢牢的保障。城市生活本就拥挤不堪，每一个铲屎官都希望能在有限的条件给予爱宠最大的自由，有了宠不失智能宠物项圈，爱宠跑多远也不担心找不回！
			</div>
		</div>
		<div class="details-link">
			
			<div>
				<router-link to="/articlesThree">下一篇：拒绝宠物丢失流浪：选对宠物防丢智能产品，能护它一生安全</router-link>
			</div>
			<div style="margin-top:15px ;">
				<router-link to="/articlesOne">上一篇：为宠物安全而研发——宠不失智能定位安全项圈</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				img:"http://static.kza666.com/kza/2022-05-25/d9fe55d0-bb95-4b4d-8ca4-61081f0419bf",
			}
		}
	}
</script>

<style lang="scss" scoped>
	.articles-warp {
		width: 100%;
		text-align: justify;
		word-break:break-all;
	}
	
	.details-content {
		font-family: Microsoft YaHei;
		font-weight: 400;
	
		>div {
			line-height: 30px;
			margin: 20px 0;
		}
	
		.img-div {
			margin: 20px 0;
			text-align: center;
	
			img {
				margin: 5px auto;
				cursor: pointer;
			}
		}
	
		.details-content-title {
			font-size: 32px;
			font-weight: 600;
			line-height: 48px;
		}
	
		.details-content-time {
			margin: 26px 0;
			font-size: 16px;
			color: #999999;
			line-height: 12px;
		}
	}
</style>
